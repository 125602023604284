import { FC, useEffect, useState } from "react";
import { FullView } from "./FullView";
import { Sidebar } from "./Sidebar";
import ComponentVisibility from "./core/ComponentVisibility";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { serverUrl } from "./core/Server";

import "./LandingPage.css";

const axios = require("axios").default;

export interface LandingPageProps {
  tradeId?: string | undefined;
}

export const LandingPage: FC<LandingPageProps> = (props) => {

  const [passedTradeId, setPassedTradeId] = useState(props.tradeId ? props.tradeId : "");
  const [resp, setResp] = useState("");
  const [sidebarRefreshSignal, setSidebarRefreshSignal] = useState(false);
  const [vis, setVis] = useState<ComponentVisibility>({
    propose: true,
    authorize: false,
    execute: false,
    portfolioView: true,
    tradeView: true,
  });
  const { publicKey } = useWallet();

  const passUpTradeId = (tradeId: string) => {
    setPassedTradeId(tradeId);
  };

  const passUpVis = (v: ComponentVisibility) => {
    setVis(v);
  };

  const passUpSidebarRefresh = () => {
    setSidebarRefreshSignal(!sidebarRefreshSignal);
  };

  async function checkTradeIdValidity() {
    let res;
    try {
      res = await axios.get(
        serverUrl +
        "?id=" +
        passedTradeId +
        "&user=" +
        (publicKey != null ? publicKey?.toString : "null")
      );
    } catch (e) {
      return;
    }
    setResp(res);
  };

  useEffect(() => {
    if (passedTradeId) {
      checkTradeIdValidity();
    }
  }, [passedTradeId]);

  if (passedTradeId) {
    if (resp === "") {
      return (
        <div>
          <h1>anagram</h1>
          <h3>Sorry, but this trade isn't valid</h3>
          <h3><a href="/">[ return to anagram ]</a></h3>
        </div>
      );
    } else {
      return (
        <div>
          {publicKey ? (
            <div>
              <Sidebar
                passUpTradeId={passUpTradeId}
                passUpVis={passUpVis}
                refreshSignal={sidebarRefreshSignal}
              />
              <FullView
                vis={vis}
                passedTradeId={passedTradeId}
                passUpSidebarRefresh={passUpSidebarRefresh}
              />
            </div>
          ) : (
            <div>
              <h1>anagram</h1>
              <h2>You just recieved a trade request! (Trade id: {passedTradeId})</h2>
              <h3>Please connect your wallet to confirm your identity</h3>
              <h3><a href="/help">[ how it works ]</a></h3>
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <div>
      {publicKey ? (
        <div>
          <Sidebar
            passUpTradeId={passUpTradeId}
            passUpVis={passUpVis}
            refreshSignal={sidebarRefreshSignal}
          />
          <FullView
            vis={vis}
            passedTradeId={passedTradeId}
            passUpSidebarRefresh={passUpSidebarRefresh}
          />
        </div>
      ) : (
        <div>
          <h1>anagram</h1>
          <h3>Please connect your wallet to continue</h3>
          <h3><a href="/help">[ how it works ]</a></h3>
        </div>
      )}
    </div>
  );
};
