import {
  PublicKey,
  TransactionInstruction,
  SystemProgram
} from "@solana/web3.js";
import BN from "bn.js";
import { TOKEN_PROGRAM_ID } from "spl-token2";

export class Asset {
  mint: PublicKey;
  amount: number;
  amount_deposited: number;
  owned_by_init: boolean;

  constructor(
    mint: PublicKey,
    amount: number,
    amount_deposited: number,
    owned_by_init: boolean
  ) {
    this.mint = mint;
    this.amount = amount;
    this.amount_deposited = amount_deposited;
    this.owned_by_init = owned_by_init;
  }
}

export class AnagramInstructions {
  static create_trade(
    program_id: PublicKey,
    initiator: PublicKey,
    counterparty: PublicKey,
    trade_pda: PublicKey,
    trade_id: number | BN,
    asset_count: number,
    bump: number,
    assets: Asset[],
  ) {
    let instr = [
      Buffer.from([4]),
      new BN(trade_id).toArrayLike(Buffer, "le", 8),
      new BN(bump).toArrayLike(Buffer, "le", 1),
      new BN(asset_count).toArrayLike(Buffer, "le", 1),
    ];
    for (var asset of assets) {
      instr.push(asset.mint.toBuffer());
      instr.push(new BN(asset.amount).toArrayLike(Buffer, "le", 8));
      instr.push(new BN(asset.amount_deposited).toArrayLike(Buffer, "le", 8));
      instr.push(asset.owned_by_init ? new BN(1).toArrayLike(Buffer, "le", 1) : new BN(0).toArrayLike(Buffer, "le", 1));
    }
    let data = Buffer.concat(instr);

    return new TransactionInstruction({
      programId: program_id,
      keys: [
        { pubkey: initiator, isSigner: true, isWritable: true },
        { pubkey: counterparty, isSigner: false, isWritable: false },
        { pubkey: trade_pda, isSigner: false, isWritable: true },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
      ],
      data: (data),
    });
  }

  static deposit(
    program_id: PublicKey,
    payer: PublicKey,
    initiator: PublicKey,
    counterparty: PublicKey,
    trade_pda: PublicKey,
    token_acct: PublicKey,
    delegate: PublicKey,
    escrow_token_acct: PublicKey,
    quantity: number,
    trade_id: number | BN,
    bump: number,
  ) {
    let instr = [
      Buffer.from([2]),
      new BN(quantity).toBuffer("le", 8),
      new BN(trade_id).toBuffer("le", 8),
      new BN(bump).toBuffer("le", 1),
    ];
    let data = Buffer.concat(instr);
    return new TransactionInstruction({
      programId: program_id,
      keys: [
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: initiator, isSigner: false, isWritable: false },
        { pubkey: counterparty, isSigner: false, isWritable: false },
        { pubkey: trade_pda, isSigner: false, isWritable: true },
        { pubkey: token_acct, isSigner: false, isWritable: true },
        { pubkey: delegate, isSigner: false, isWritable: false },
        { pubkey: escrow_token_acct, isSigner: false, isWritable: true },
        { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
        { pubkey: SystemProgram.programId, isSigner: false, isWritable: false },
      ],
      data: (data),
    });
  }

  static withdraw(
    program_id: PublicKey,
    payer: PublicKey,
    token_acct: PublicKey,
    delegate: PublicKey,
    escrow_token_account: PublicKey,
    spl_token_program_id: PublicKey,
    system_program_id: PublicKey,
    quantity: number,
  ) {
    let instr = [Buffer.from([3]), new BN(quantity).toBuffer("le", 8)];
    let data = Buffer.concat(instr);
    return new TransactionInstruction({
      programId: program_id,
      keys: [
        { pubkey: payer, isSigner: true, isWritable: false },
        { pubkey: token_acct, isSigner: false, isWritable: true },
        { pubkey: delegate, isSigner: false, isWritable: false },
        { pubkey: escrow_token_account, isSigner: false, isWritable: true },
        { pubkey: spl_token_program_id, isSigner: false, isWritable: false },
        { pubkey: system_program_id, isSigner: false, isWritable: false },
      ],
      data: (data),
    });
  }

  static accept_trade(
    program_id: PublicKey,
    initiator: PublicKey,
    counterparty: PublicKey,
    trade_pda: PublicKey,
    trade_id: number | BN,
    bump: number,
  ) {
    let instr = [
      Buffer.from([5]),
      new BN(trade_id).toArrayLike(Buffer, "le", 8),
      new BN(bump).toArrayLike(Buffer, "le", 1),
    ];
    let data = Buffer.concat(instr);
    return new TransactionInstruction({
      programId: program_id,
      keys: [
        { pubkey: initiator, isSigner: false, isWritable: false },
        { pubkey: counterparty, isSigner: true, isWritable: false },
        { pubkey: trade_pda, isSigner: false, isWritable: true },
      ],
      data: (data),
    });
  }

  static lock_trade(
    program_id: PublicKey,
    payer: PublicKey,
    initiator: PublicKey,
    counterparty: PublicKey,
    trade_pda: PublicKey,
    trade_id: number | BN,
    bump: number,
  ) {
    let instr = [
      Buffer.from([6]),
      new BN(trade_id).toBuffer("le", 8),
      new BN(bump).toBuffer("le", 1),
    ];
    let data = Buffer.concat(instr);
    return new TransactionInstruction({
      programId: program_id,
      keys: [
        { pubkey: payer, isSigner: true, isWritable: true },
        { pubkey: initiator, isSigner: false, isWritable: false },
        { pubkey: counterparty, isSigner: false, isWritable: false },
        { pubkey: trade_pda, isSigner: false, isWritable: true },
      ],
      data: (data),
    });
  }
}
