import { FC, useState, useEffect } from "react";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import {
  Metadata,
  MetadataData,
} from "@metaplex-foundation/mpl-token-metadata";
import {
  PublicKey,
  RpcResponseAndContext,
  AccountInfo,
  ParsedAccountData,
} from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";
import Popup from "reactjs-popup";
import { Grid, Divider, CircularProgress } from "@mui/material";

import Modal from "./Modal";
import AssetData from "./core/AssetData";
import { AssetView } from "./AssetView";
import ComponentVisibility from "./core/ComponentVisibility";

import "./SwapView.css";
import { assetQtyError, tradeQtyError, invalidPubkeyError } from "./Errors";
import { getAssociatedTokenAddress } from "spl-token2";

export interface SwapViewProps {
  isCurrUser: boolean;
  pubkey: string;
  passUpUser: Function;
  assets: AssetData[];
  setAssets: Function;
  trades: AssetData[];
  setTrades: Function;
  vis: ComponentVisibility;
}

export const SwapView: FC<SwapViewProps> = ({
  isCurrUser,
  pubkey,
  passUpUser,
  assets,
  setAssets,
  trades,
  setTrades,
  vis,
}) => {
  // 0 = not loaded, 1 = loading, 2 = loaded
  const [assetsLoading, setAssetsLoading] = useState(0);

  const [nfts, setNfts] = useState({});
  const [tokenList, setTokenList] = useState({});

  const [pkey, setPkey] = useState(pubkey);
  const [amountToTrade, setAmountToTrade] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  let conn = useConnection().connection;
  let mintSet = new Map();

  useEffect(() => {
    let pubkeyKey: PublicKey;
    try {
      pubkeyKey = new PublicKey(pubkey);
    } catch {
      return;
    }
    getAssets(pubkeyKey);
    setPkey(pubkey);
  }, [pubkey]);

  async function getAssets(pubkey: PublicKey) {
    setAssetsLoading(1);
    try {
      let ta = await conn.getParsedTokenAccountsByOwner(pubkey, {
        programId: TOKEN_PROGRAM_ID,
      });
      let response = await fetch(
        "https://raw.githubusercontent.com/solana-labs/token-list/main/src/tokens/solana.tokenlist.json"
      );
      let json = await response.json();
      setTokenList(json);

      for (let entry of (json as any).tokens) {
        let key: string = entry.address;
        let symbol: string = entry.symbol;
        let name: string = entry.name;
        let decimals: string = entry.decimals;
        let logoURI: string = entry.logoURI;

        mintSet.set(key, { symbol, name, decimals, logoURI });
      }

      let tokenAssets: AssetData[] = [];
      (
        ta as RpcResponseAndContext<
          {
            account: AccountInfo<ParsedAccountData>;
            pubkey: PublicKey;
          }[]
        >
      ).value.map((tokenAccount) => {
        let data = tokenAccount.account.data.parsed;
        let tokenData = mintSet.get(data.info.mint);
        if (!tokenData) return 0;
        if (data.info.tokenAmount.amount == 0) return 0;

        tokenAssets.push({
          name: tokenData.name,
          logoURI: tokenData.logoURI,
          tokenAcct: tokenAccount.pubkey.toString(),
          mint: data.info.mint,
          amount: data.info.tokenAmount.uiAmount,
          isNFT: false,
          decimal: tokenData.decimals as number,
          actualAmt:
            data.info.tokenAmount.uiAmount * 10 ** (tokenData.decimals as number),
        });
        return 0;
      });

      setNfts({});
      let nftMetadata = await Metadata.findDataByOwner(conn, pubkey);
      setNfts(nftMetadata);

      let nftInfo = await Metadata.findInfoByOwner(conn, pubkey);
      let nftKeys = Array.from(nftInfo.keys());

      let nftAssets: AssetData[] = [];
      for (let i = 0; i < nftMetadata.length; i++) {
        let mint = new PublicKey(nftMetadata[i].mint);
        let tokenAcctPkey = await getAssociatedTokenAddress(mint, pubkey, true);
        nftAssets.push({
          name: nftMetadata[i].data.name,
          logoURI: nftMetadata[i].data.uri,
          tokenAcct: tokenAcctPkey.toString(),
          mint: nftMetadata[i].mint,
          isNFT: true,
          actualAmt: 1,
        });
      }
      setAssets(tokenAssets.concat(nftAssets));
    } catch (e: any) {
      setAssetsLoading(0);
      setModalTitle("load assets failed");
      setModalText(e.toString());
      setModalOpen(true);
      return;
    };

    setAssetsLoading(2);
  }

  let borderLeft = isCurrUser ? "0" : "0" + "em solid gray";
  let borderRight = isCurrUser ? "0" : "0" + "em solid gray";

  const input = () => {
    return (
      <div className="inputDiv">
        <div>
          <small className="window-title">
            {isCurrUser ? "you" : "?"}, aka
          </small>
        </div>
        <input
          className="load-portfolio-textfield"
          value={pkey}
          spellCheck={false}
          autoComplete={"off"}
          placeholder={"who"}
          maxLength={44}
          onChange={(e) => {
            setPkey(e.target.value.trim());
          }}
        ></input>

        <h2
          className="load-portfolio-button"
          onClick={() => {
            setAssets([]);
            setTrades([]);
            let lpkey: PublicKey;
            try {
              if (isCurrUser) {
                lpkey = new PublicKey(pubkey);
                setPkey(pubkey);
              } else {
                lpkey = new PublicKey(pkey);
              }
            } catch (e: any) {
              invalidPubkeyError(
                setModalOpen,
                setModalTitle,
                setModalText
              );
              return;
            };
            getAssets(lpkey);
            passUpUser(lpkey);
          }}
        >
          [ load ]
        </h2>
      </div>
    );
  };

  const swapPool = () => {
    if (trades.length == 0) {
      return (
        <div
          className={
            vis.portfolioView ? "swap-pool" : "swap-pool swap-pool-only"
          }
        >
          <small className="window-title">
            {vis.portfolioView && "and "}
            {vis.portfolioView &&
              (isCurrUser ? "you will contribute ..." : "they will contribute ...")}
            {!vis.portfolioView &&
              (isCurrUser ? "you contribute" : "they contribute")}
          </small>
          <div
            className="portfolio-grid swap-pool-grid"
            style={{
              borderLeft: borderLeft,
              borderRight: borderRight,
            }}
          >
            <div className="items-grid">
              <Grid container spacing={1}>
                <h2 className="empty-tag">( empty )</h2>
              </Grid>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        className={vis.portfolioView ? "swap-pool" : "swap-pool swap-pool-only"}
      >
        <small className="window-title">
          {vis.portfolioView && "and "}
          {vis.portfolioView ? (isCurrUser ? "you contribute ..." : "they contribute ...") :
            (isCurrUser ? "you contribute" : "they contribute")}
        </small>
        <div
          className="portfolio-grid swap-pool-grid"
          style={{
            borderLeft: borderLeft,
            borderRight: borderRight,
          }}
        >
          <div className="items-grid">
            <Grid container spacing={1}>
              {trades.map((value) => {
                return (
                  <Grid
                    onClick={() => {
                      if (!vis.tradeViewEditLock) {
                        let tmp: AssetData[] = [];
                        let flag = false;
                        for (let i = 0; i < assets.length; i++) {
                          if (assets[i].name == value.name) {
                            assets[i].amount =
                              (assets[i].amount as number) +
                              (value.amount as number);
                            assets[i].actualAmt =
                              (assets[i].amount as number) *
                              10 ** (assets[i].decimal as number);
                            tmp = assets;
                            flag = true;
                            break;
                          }
                        }
                        if (!flag) tmp = assets.concat(value);
                        let tmp2: AssetData[] = [];
                        for (let i = 0; i < trades.length; i++) {
                          if (trades[i] === value) {
                            tmp2 = trades
                              .slice(0, i)
                              .concat(trades.slice(i + 1, trades.length));
                            setTrades(tmp2);
                            break;
                          }
                        }
                        setAssets(tmp);
                        setTrades(tmp2);
                      }
                    }}
                    item
                    xs={3}
                  >
                    <AssetView
                      name={value.name}
                      amount={value.amount}
                      nft={
                        value.isNFT
                          ? { uri: value.logoURI }
                          : { image: value.logoURI }
                      }
                      pkey={value.mint}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  if (assetsLoading != 2) {
    const items: number[] = [0];

    return (
      <div className="user-wrapper">
        {vis.portfolioView && (
          <div className="asset-load">
            {input()}
            <div
              className="portfolio-grid"
              style={{
                borderLeft: borderLeft,
                borderRight: borderRight,
              }}
            >
              {assetsLoading == 1 && <CircularProgress />}
              <div className="items-grid">
                <Grid container spacing={1}>
                  {items.map((val) => {
                    return <Grid item xs={3}></Grid>;
                  })}
                </Grid>
              </div>
            </div>
          </div>
        )}
        <Divider />
        {vis.tradeView && swapPool()}
        <Modal
          open={modalOpen}
          text={modalText}
          title={modalTitle}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      </div>
    );
  }

  let NFTset = new Set<string>();

  try {
    for (let nft of nfts as MetadataData[]) {
      NFTset.add(nft.mint);
    }

    for (let entry of (tokenList as any).tokens) {
      let key: string = entry.address;
      let symbol: string = entry.symbol;
      let name: string = entry.name;
      let decimals: string = entry.decimals;
      let logoURI: string = entry.logoURI;

      mintSet.set(key, { symbol, name, decimals, logoURI });
    }
  } catch (e) {
    console.log("Lists not initialized");
  }

  if (assets == null || assets.length == 0) {
    return (
      <div className="user-wrapper">
        {vis.portfolioView && (
          <div className="asset-load">
            {input()}
            <div
              className="portfolio-grid"
              style={{
                borderLeft: borderLeft,
                borderRight: borderRight,
              }}
            >
              <div className="items-grid">
                <Grid container spacing={1}>
                  <h2 className="empty-tag">( empty )</h2>
                </Grid>
              </div>
            </div>
          </div>
        )}
        <Divider />
        {vis.tradeView && swapPool()}
        <Modal
          open={modalOpen}
          text={modalText}
          title={modalTitle}
          onClose={() => {
            setModalOpen(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="user-wrapper">
      {vis.portfolioView && (
        <div className="asset-load">
          {input()}
          <div
            className="portfolio-grid"
            style={{
              borderLeft: borderLeft,
              borderRight: borderRight,
            }}
          >
            <div className="items-grid">
              <Grid container spacing={1}>
                {assets.map((asset) => {
                  if (!asset.isNFT) {
                    return (
                      <Popup
                        className="add-to-pool-popup"
                        disabled={asset.isNFT}
                        trigger={
                          <Grid item xs={3}>
                            <AssetView
                              name={asset.name}
                              amount={asset.amount}
                              nft={
                                asset.isNFT
                                  ? { uri: asset.logoURI }
                                  : { image: asset.logoURI }
                              }
                              pkey={asset.mint}
                            />
                          </Grid>
                        }
                      >
                        <div className="popup-field-div">
                          <div>
                            <input
                              className="add-amount-input"
                              value={amountToTrade}
                              spellCheck={false}
                              autoComplete={"off"}
                              placeholder={""}
                              onChange={(e) => {
                                setAmountToTrade(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div>
                            <h2
                              className="add-to-pool-button"
                              onClick={() => {
                                if (trades.length >= 16) {
                                  tradeQtyError(
                                    setModalOpen,
                                    setModalTitle,
                                    setModalText
                                  );
                                  return;
                                }
                                let attFloat = parseFloat(amountToTrade);
                                setAmountToTrade("");
                                let tmp: AssetData[] = [];
                                let flag = false;
                                for (let i = 0; i < trades.length; i++) {
                                  if (trades[i].name == asset.name) {
                                    trades[i].amount =
                                      (trades[i].amount as number) +
                                      (attFloat as number);
                                    trades[i].actualAmt =
                                      (trades[i].amount as number) *
                                      10 ** (trades[i].decimal as number);
                                    tmp = trades;
                                    flag = true;
                                    break;
                                  }
                                }
                                if (!flag) {
                                  tmp = trades.concat({
                                    name: asset.name,
                                    logoURI: asset.logoURI,
                                    tokenAcct: asset.tokenAcct,
                                    mint: asset.mint,
                                    amount: attFloat,
                                    decimal: asset.decimal,
                                    actualAmt:
                                      attFloat *
                                      10 ** (asset.decimal as number),
                                    isNFT: asset.isNFT,
                                  });
                                }

                                let tmp2: AssetData[] = [];
                                for (let i = 0; i < assets.length; i++) {
                                  if (assets[i] === asset) {
                                    if (
                                      !attFloat ||
                                      attFloat <= 0 ||
                                      attFloat > (assets[i].amount as number)
                                    ) {
                                      assetQtyError(
                                        setModalOpen,
                                        setModalTitle,
                                        setModalText
                                      );
                                      return;
                                    }
                                    if (attFloat == (asset.amount as number)) {
                                      tmp2 = assets
                                        .slice(0, i)
                                        .concat(
                                          assets.slice(i + 1, assets.length)
                                        );
                                      setAssets(tmp2);
                                    } else {
                                      assets[i].amount =
                                        (assets[i].amount as number) - attFloat;
                                      tmp2 = assets;
                                      setAssets(tmp2);
                                    }
                                    break;
                                  }
                                }
                                setTrades(tmp);
                              }}
                            >
                              [ add to pool ]
                            </h2>
                          </div>
                        </div>
                      </Popup>
                    );
                  } else {
                    return (
                      <Grid
                        onClick={() => {
                          if (trades.length >= 16) {
                            tradeQtyError(
                              setModalOpen,
                              setModalTitle,
                              setModalText
                            );
                            return;
                          }
                          let tmp = trades.concat(asset);
                          for (let i = 0; i < assets.length; i++) {
                            if (assets[i] === asset) {
                              let tmp2 = assets
                                .slice(0, i)
                                .concat(assets.slice(i + 1, assets.length));
                              setAssets(tmp2);
                              break;
                            }
                          }
                          setTrades(tmp);
                        }}
                        item
                        xs={3}
                      >
                        <AssetView
                          pkey={asset.mint}
                          name={asset.name}
                          amount={asset.amount}
                          nft={{ uri: asset.logoURI }}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </div>
          </div>
        </div>
      )}
      <Divider />
      {vis.tradeView && swapPool()}
      <Modal
        open={modalOpen}
        text={modalText}
        title={modalTitle}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};
