import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
    ConnectionProvider,
    WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { NetworkSelect } from "./NetworkSelect";

import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { ReactNode, useMemo, useState } from "react";

import "./FiatWidget.css";
import "./WalletConnection.css";

export const FiatWidget: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Context>
            {children}
        </Context>
    );
};

export default FiatWidget;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const [network, setNetwork] = useState(WalletAdapterNetwork.Mainnet);
    const [walletPkey, setWalletPkey] = useState("");

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
        ],
        [network]
    );

    return (
        <div>
            <NetworkSelect setNetwork={setNetwork} />
            <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                    <WalletModalProvider>
                        <FTXModal setWalletPkey={setWalletPkey} />
                        {children}
                    </WalletModalProvider>
                </WalletProvider>
            </ConnectionProvider>
        </div>
    );
};

export interface FTXModalProps {
    setWalletPkey: Dispatch<SetStateAction<string>>;
}

const FTXModal: FC<FTXModalProps> = ({ setWalletPkey }) => {
    const { publicKey } = useWallet();

    useEffect(() => {
        if (publicKey?.toBase58()) setWalletPkey(publicKey.toBase58());
    }, [publicKey])

    return (
        <div className="fiat-widget">
            {!publicKey?.toBase58() ? (
                <WalletMultiButton className="wallet-button-connect">[ connect ]</WalletMultiButton>
            ) : (
                <WalletMultiButton className="wallet-button-connected">[ connected ]</WalletMultiButton>
            )}
        </div>
    );
};
