import { FC } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { FiatWidget } from "./FiatWidget";
import { LandingPage } from "./LandingPage";
import { HelpPage } from "./HelpPage";

require("./App.css");

const App: FC = () => {
  function GetTrade() {
    let { tradeid } = useParams();
    return (
      <FiatWidget children={<LandingPage tradeId={tradeid as string} />} />
    );
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<FiatWidget children={<LandingPage />} />} />
        <Route path="/trade/:tradeid" element={<GetTrade />}></Route>
        <Route path="/help" element={<HelpPage />}></Route>
      </Routes>
    </div>
  );
};
export default App;
