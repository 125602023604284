import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import App from "./App";
import "./index.css";
const mixpanel = require("mixpanel-browser");

mixpanel.init("82e7de76c867f6a27226688c24693ce6", { debug: false });
mixpanel.track("App started");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <BrowserView>
        <App />
      </BrowserView>
      <MobileView>
        <h3>Please open this page on a desktop browser</h3>
      </MobileView>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
