import { FC, useEffect, useState } from "react";
import { FullView } from "./FullView";
import { Sidebar } from "./Sidebar";
import ComponentVisibility from "./core/ComponentVisibility";
import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { serverUrl } from "./core/Server";

import "./HelpPage.css";

const axios = require("axios").default;

export interface HelpPageProps {
  entrypoint?: string | undefined;
}

export const HelpPage: FC<HelpPageProps> = (props) => {
  return (
    <div>
      <h1>anagram</h1>
      <small className="helppage-small"><a href="https://anagramgg.medium.com/">[ medium ]</a></small>
      <small className="helppage-small"><a href="https://twitter.com/anagramgg">[ twitter ]</a></small>
    </div>
  );
};
