import { FC, useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Drawer, Divider, List, ListItem } from "@mui/material";
import { LoadTradeItem } from "./LoadTradeItem";
import { serverUrl } from "./core/Server";
import "./Sidebar.css";
import Modal from "./Modal";

const axios = require("axios").default;
const mixpanel = require("mixpanel-browser");

export interface SidebarProps {
  passUpTradeId: Function;
  passUpVis: Function;
  refreshSignal: boolean;
}

export const Sidebar: FC<SidebarProps> = ({
  passUpTradeId,
  passUpVis,
  refreshSignal,
}) => {
  const { publicKey } = useWallet();

  let [pendingTrades, setPendingTrades] = useState([{}]);
  let [receivedTrades, setReceivedTrades] = useState([{}]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    refreshPending(publicKey);
  }, [publicKey, refreshSignal]);

  const onClickOnPropose = () => {
    mixpanel.track("Clicked on sidebar", {
      row: "propose",
    });
    passUpVis({
      propose: true,
      authorize: false,
      execute: false,
      portfolioView: true,
      tradeView: true,
    });
    passUpTradeId("propose");
  };

  const onClickOnTrade = (e: any) => {
    mixpanel.track("Clicked on sidebar", {
      row: "trade",
      trade_id: e,
    });
    if (e == "(empty)") return;
    passUpVis({
      propose: false,
      authorize: true,
      execute: true,
      portfolioView: false,
      tradeView: true,
      tradeViewEditLock: true,
    });
    passUpTradeId(e);
  };

  const onClickOnTwitter = (e: any) => {
    mixpanel.track("Clicked on sidebar", {
      row: "twitter",
    });
    window.open("https://twitter.com/anagramgg", '_blank');
  };

  const onClickOnMedium = (e: any) => {
    mixpanel.track("Clicked on sidebar", {
      row: "medium",
    });
    window.open("https://anagramgg.medium.com/", '_blank');
  };

  const refreshPending = async (e: any) => {
    if (e as Event) {
      mixpanel.track("Clicked on sidebar", {
        row: "refresh",
      });
    }
    console.log("Getting trades for", publicKey?.toString());
    const res = await axios.get(
      serverUrl + "/user?user=" + publicKey?.toString()
    );
    console.log(res.data);
    setPendingTrades(
      res.data.filter(
        (trade: any) =>
          JSON.parse(trade.trade).initiator.pubkey === publicKey?.toString()
      )
    );
    setReceivedTrades(
      res.data.filter(
        (trade: any) =>
          JSON.parse(trade.trade).counterparty.pubkey === publicKey?.toString()
      )
    );
  };

  const getFilteredTrades = (unfiltered: {}[], status: string) => {
    let trades = unfiltered.filter((trade: any) => trade.status === status);
    if (trades.length == 0) {
      trades = [{ id: "(empty)", status: "placeholder" }];
    }
    return trades;
  };

  const drawer = (
    <div>
      <List>
        <ListItem
          button
          key="propose"
          onClick={() => {
            window.history.pushState(null, "", "/");
            onClickOnPropose();
          }}
        >
          <small className="list-item-text">propose trade</small>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={"refresh"} onClick={refreshPending}>
          <small className="list-item-text">refresh</small>
        </ListItem>
      </List>
      <Divider sx={{ fontFamily: "Fira Sans", color: "gray" }}>load</Divider>
      <List>
        <ListItem key="load-trade">
          <LoadTradeItem
            passUpTradeId={passUpTradeId}
            passUpVis={passUpVis}
          />
        </ListItem>
      </List>
      <Divider sx={{ fontFamily: "Fira Sans", color: "gray" }}>sent</Divider>
      <List>
        {getFilteredTrades(pendingTrades, "proposed").map(
          (trade: any, index) => (
            <ListItem
              button
              key={trade.id}
              onClick={() => {
                if (trade.id != "(empty)") {
                  window.history.pushState(null, "", "/trade/" + trade.id);
                }
                onClickOnTrade(trade.id);
              }}
            >
              <small className="list-item-text-lighter">{trade.id}</small>
            </ListItem>
          )
        )}
      </List>
      <Divider sx={{ fontFamily: "Fira Sans", color: "gray" }}>received</Divider>
      <List>
        {getFilteredTrades(receivedTrades, "proposed").map(
          (trade: any, index) => (
            <ListItem
              button
              key={trade.id}
              onClick={() => {
                if (trade.id != "(empty)") {
                  window.history.pushState(null, "", "/trade/" + trade.id);
                }
                onClickOnTrade(trade.id);
              }}
            >
              <small className="list-item-text-lighter">{trade.id}</small>
            </ListItem>
          )
        )}
      </List>
      <Divider sx={{ fontFamily: "Fira Sans", color: "gray" }}>executed</Divider>
      <List>
        {getFilteredTrades(
          receivedTrades.concat(pendingTrades),
          "executed"
        ).map((trade: any, index) => (
          <ListItem
            button
            key={trade.id}
            onClick={() => {
              if (trade.id != "(empty)") {
                window.history.pushState(null, "", "/trade/" + trade.id);
              }
              onClickOnTrade(trade.id);
            }}
          >
            <small className="list-item-text-lighter">{trade.id}</small>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button key={"twitter"} onClick={onClickOnTwitter}>
          <small className="list-item-text">twitter</small>
        </ListItem>
        <ListItem button key={"medium"} onClick={onClickOnMedium}>
          <small className="list-item-text">medium</small>
        </ListItem>
      </List>
      <Modal
        open={modalOpen}
        text={modalText}
        title={modalTitle}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: "20%",
          backgroundColor: "#f2f2f2",
        },
      }}
      variant="permanent"
    >
      {drawer}
    </Drawer>
  );
};
