import { FC, useEffect, useState } from "react";
import "./AssetView.css";

const default_img = require("./assets/default.png");

type AssetViewFilter = { uri: string } | { image: string };

export interface AssetViewProps {
  name: string;
  amount: number | undefined;
  nft: AssetViewFilter;
  pkey: string;
}

export const AssetView: FC<AssetViewProps> = ({ name, amount, nft, pkey }) => {
  const [image, setImage] = useState(default_img);

  async function getImageFromUri(nft: AssetViewFilter) {
    if ("image" in nft) {
      setImage(nft.image);
      return;
    }

    try {
      let response = await fetch(nft.uri);
      let json = await response.json();
      setImage(json.image);
    } catch (e: any) {
      console.log("Image for token or NFT not found");
    }

  }

  useEffect(() => {
    getImageFromUri(nft);
  }, [nft]);

  return (
    <div className="itemWrap">
      <div>
        <img
          className="displayimg"
          src={image as string}
          srcSet={image as string}
          alt={name}
          loading="lazy"
        />
      </div>
      <div>
        <small className="name-text">{name}</small><br />
        <small className="name-text amount-text">{amount?.toLocaleString()}</small>
      </div>
    </div>
  );
};
