import { FC, useState } from "react";
import "./Sidebar.css";
import Modal from "./Modal";
import { tradeIdEmptyError } from "./Errors";

export interface LoadTradeItemProps {
  passUpTradeId: Function;
  passUpVis: Function;
}

export const LoadTradeItem: FC<LoadTradeItemProps> = ({
  passUpTradeId,
  passUpVis,
}) => {

  const [tradeId, setTradeId] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const onLoadTradeValueChange = (e: any) => {
    e.preventDefault();
    setTradeId(e.target.value);
  };

  return (
    <div className="sidebar-load-trade-div">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!tradeId || tradeId == "") {
            tradeIdEmptyError(setModalOpen, setModalTitle, setModalText);
          } else {
            window.history.pushState(null, "", "/trade/" + tradeId);
            passUpTradeId(tradeId);
          }
        }}
      >
        <input
          className="load-trade-textfield"
          // defaultValue={tradeId}
          placeholder="trade id"
          autoComplete="off"
          spellCheck="false"
          onChange={(e) => { onLoadTradeValueChange(e); }}
        />
        <button
          className="load-trade-button"
          type="submit"
        >
          [ load ]
        </button>
      </form>
      <Modal
        open={modalOpen}
        text={modalText}
        title={modalTitle}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>

  );
}
