import React, {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { ButtonProps } from "@solana/wallet-adapter-react-ui/src/Button";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

export const Button: FC<ButtonProps> = (props) => {
    const justifyContent =
        props.endIcon || props.startIcon ? "space-between" : "center";

    return (
        <button
            className={`wallet-adapter-button ${props.className || ""}`}
            disabled={props.disabled}
            onClick={props.onClick}
            style={{ justifyContent, ...props.style }}
            tabIndex={props.tabIndex || 0}
            type="button"
        >
            {props.startIcon && (
                <i className="wallet-adapter-button-start-icon">{props.startIcon}</i>
            )}
            {props.children}
            {props.endIcon && (
                <i className="wallet-adapter-button-end-icon">{props.endIcon}</i>
            )}
        </button>
    );
};

export interface NetworkSelectProps {
    setNetwork: Dispatch<SetStateAction<WalletAdapterNetwork>>;
}



export const NetworkSelect: FC<NetworkSelectProps> = ({ setNetwork }) => {
    const [network_state, setNetworkState] = useState(
        WalletAdapterNetwork.Mainnet
    );
    const [active, setActive] = useState(false);
    const ref = useRef<HTMLUListElement>(null);



    const openDropdown = useCallback(() => {
        setActive(true);
    }, []);

    const closeDropdown = useCallback(() => {
        setActive(false);
    }, []);

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const node = ref.current;

            // Do nothing if clicking dropdown or its descendants
            if (!node || node.contains(event.target as Node)) return;

            closeDropdown();
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [ref, closeDropdown]);

    const setMainnet = useCallback(() => {
        setNetwork(WalletAdapterNetwork.Mainnet);
        setNetworkState(WalletAdapterNetwork.Mainnet);
        closeDropdown();
    }, []);

    const setDevnet = useCallback(() => {
        setNetwork(WalletAdapterNetwork.Devnet);

        setNetworkState(WalletAdapterNetwork.Devnet);
        closeDropdown();
    }, []);

    const setTestnet = useCallback(() => {
        setNetwork(WalletAdapterNetwork.Testnet);

        setNetworkState(WalletAdapterNetwork.Testnet);
        closeDropdown();
    }, []);

    return (

        <div className="wallet-adapter-dropdown">
            <Button
                aria-expanded={active}
                className="wallet-adapter-button-trigger"
                onClick={openDropdown}
            >
               [ {network_state} {active ? " ↑ " : " ↓ "} ]
            </Button>
            <ul
                aria-label="dropdown-list"
                className={`wallet-adapter-dropdown-list ${active && "wallet-adapter-dropdown-list-active"
                    }`}
                ref={ref}
                role="menu"
            >
                <li
                    onClick={setMainnet}
                    className="wallet-adapter-dropdown-list-item"
                    role="menuitem"
                >

                    [ {WalletAdapterNetwork.Mainnet} ]
                </li>
                <li
                    onClick={setDevnet}
                    className="wallet-adapter-dropdown-list-item"
                    role="menuitem"
                >

                   [ {WalletAdapterNetwork.Devnet} ]
                </li>
            </ul>
        </div>
    );
};




