import { FC, useEffect, useState } from "react";
import { Token, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Account, getAccount } from "spl-token2";

import { PublicKey, Transaction, TransactionInstruction, SystemProgram } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Divider, Grid } from "@mui/material";

import Modal from "./Modal";
import { SwapView } from "./SwapView";
import AssetData from "./core/AssetData";
import ComponentVisibility from "./core/ComponentVisibility";

import "./FullView.css";
import {
  invalidParticipantError,
  tradeNotFoundError,
  tradeEmptyError,
  tradeQtyError,
  transactionFailedToSend,
} from "./Errors";
import { serverUrl, anagramProgramId, delegateAcct } from "./core/Server";
import { Asset, AnagramInstructions } from "./AnagramInstructions";

import BN from "bn.js";
import { WalletError } from "@solana/wallet-adapter-base";

const axios = require("axios").default;
const mixpanel = require("mixpanel-browser");

export interface FullViewProps {
  vis: ComponentVisibility;
  passedTradeId: string;
  passUpSidebarRefresh: Function;
}

export const FullView: FC<FullViewProps> = (props) => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();

  const [vis, setVis] = useState(props.vis);
  let passedTradeId = props.passedTradeId;

  const [pageTitle, setPageTitle] = useState("anagram");

  const [yourAssets, setYourAssets] = useState<AssetData[]>([]);
  const [theirAssets, setTheirAssets] = useState<AssetData[]>([]);

  const [yourTrades, setYourTrades] = useState<AssetData[]>([]);
  const [theirTrades, setTheirTrades] = useState<AssetData[]>([]);

  const [yourUser, setYourUser] = useState<PublicKey>();
  const [theirUser, setTheirUser] = useState<PublicKey>();

  const [tradeIdInput, setTradeIdInput] = useState("");
  const [tradeStatus, setTradeStatus] = useState("");
  const [tradeRole, setTradeRole] = useState("");
  const [tradeCounterparty, setTradeCounterparty] = useState("");
  const [onChainTradeId, setOnChainTradeId] = useState<BN>(new BN(0));

  const [modalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    if (publicKey != null) setYourUser(publicKey);
    if (passedTradeId == "propose") {
      setYourTrades([]);
      setTheirTrades([]);
    } else if (passedTradeId != "") {
      getTrade(passedTradeId);
    }
    // Disable title shuffling for now since it could be confusing
    // shuffleTitle();
  }, [passedTradeId]);

  useEffect(() => {
    mixpanel.track("Wallet connected", {
      pubkey: publicKey,
    });
    if (publicKey != null) setYourUser(publicKey);
  }, [publicKey]);

  useEffect(() => {
    setVis(props.vis);
  }, [props.vis]);

  useEffect(() => {
    if (vis.portfolioView) {
      setYourTrades([]);
      setTheirTrades([]);
      setYourAssets([]);
      setTheirAssets([]);
    }
  }, [connection.rpcEndpoint]);

  const postTrade = async () => {
    console.log(yourUser?.toString(), yourTrades);
    console.log(theirUser?.toString(), theirTrades);

    if (yourTrades.length === 0 || theirTrades.length === 0) {
      tradeEmptyError(setModalOpen, setModalTitle, setModalText);
      return;
    }

    if (yourTrades.length + theirTrades.length > 16) {
      tradeQtyError(setModalOpen, setModalTitle, setModalText);
      return;
    }

    let onchain_tradeid = new BN(Math.floor(Math.random() * 1000000));
    let seeds = [
      Buffer.from("anagram", "utf8"),
      Buffer.from("trade", "utf8"),
      (yourUser as PublicKey).toBuffer(),
      (theirUser as PublicKey).toBuffer(),
      onchain_tradeid.toArrayLike(Buffer, "le", 8),
    ];

    let [trade_pda, trade_pda_bump] = await PublicKey.findProgramAddress(seeds, anagramProgramId);
    console.log("Trade PDA:", trade_pda.toString());

    /// Construct Transaction
    let transaction = new Transaction();

    /// Approve Instruction
    for (const trade of yourTrades) {
      let newApproveAmt = trade.actualAmt as number;
      let tokenAcct: Account = await getAccount(
        connection,
        new PublicKey(trade.tokenAcct)
      );
      if (tokenAcct.delegate?.toString() === delegateAcct.toString())
        newApproveAmt += Number(tokenAcct.delegatedAmount);
      const instr = Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        new PublicKey(trade.tokenAcct),
        delegateAcct,
        yourUser as PublicKey,
        [],
        newApproveAmt
      );
      transaction.add(instr);
    }

    /// Fee Instruction
    const fee_instr = SystemProgram.transfer({
      fromPubkey: yourUser as PublicKey,
      toPubkey: delegateAcct,
      lamports: 1,
    });
    transaction.add(fee_instr);

    /// Create Trade On-chain Instruction
    let assets = [];
    for (const trade of yourTrades) {
      let tokenAcct: Account = await getAccount(
        connection,
        new PublicKey(trade.tokenAcct)
      );
      let assetMint = tokenAcct?.mint;
      let asset = new Asset(
        assetMint,
        trade.actualAmt as number,
        0,
        true,
      );
      assets.push(asset);
    }
    for (const trade of theirTrades) {
      let tokenAcct: Account = await getAccount(
        connection,
        new PublicKey(trade.tokenAcct)
      );
      let assetMint = tokenAcct?.mint;
      let asset = new Asset(
        assetMint,
        trade.actualAmt as number,
        0,
        false,
      );
      assets.push(asset);
    }

    const trade_instr = AnagramInstructions.create_trade(
      anagramProgramId,
      publicKey as PublicKey,
      theirUser as PublicKey,
      trade_pda,
      onchain_tradeid, // trade ID
      assets.length, // Asset count
      trade_pda_bump, // bump
      assets, // assets
    );
    transaction.add(trade_instr);

    setModalTitle("waiting for token approval");
    setModalText("");
    setModalOpen(true);

    let signature, retries = 3;
    while (retries != 0) {
      try {
        signature = await sendTransaction(transaction, connection);
        retries = 0;
      } catch (e: any) {
        console.log((e as WalletError).error.response.status);
        if (retries == 0 || (e as WalletError).error.response.status != 503) {
          transactionFailedToSend(setModalTitle, setModalText, setModalOpen);
          return;
        } else {
          console.log("RPC 503 error, retrying");
          retries -= 1;
        }
      }
    }
    setModalTitle("waiting for transaction confirmation");

    await connection.confirmTransaction(signature as string, "confirmed");

    const res = await axios.post(
      serverUrl,
      {
        initiator: {
          pubkey: yourUser?.toString(),
          assets: yourTrades,
        },
        counterparty: {
          pubkey: theirUser?.toString(),
          assets: theirTrades,
        },
        onchain_tradeid: onchain_tradeid.toString(),
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    setModalTitle("proposal sent successfully");
    setModalText(
      "Trade ID: " +
      res.data.toString() +
      "\n\nTrade URL: https://anagram.gg/trade/" +
      res.data.toString() +
      "\n\nApproval signature: " +
      signature
    );

    mixpanel.track("Proposed a trade", {
      trade: {
        initiator: {
          pubkey: yourUser?.toString(),
          assets: yourTrades,
        },
        counterparty: {
          pubkey: theirUser?.toString(),
          assets: theirTrades,
        },
      },
      trade_id: res.data.toString(),
      approval_signature: signature,
    });

    props.passUpSidebarRefresh();
    console.log(res.data);
  };

  const approveForCp = async () => {
    let seeds = [
      Buffer.from("anagram", "utf8"),
      Buffer.from("trade", "utf8"),
      (theirUser as PublicKey).toBuffer(),
      (yourUser as PublicKey).toBuffer(),
      onChainTradeId.toArrayLike(Buffer, "le", 8),
    ];

    let [trade_pda, trade_pda_bump] = await PublicKey.findProgramAddress(seeds, anagramProgramId);
    console.log("Trade PDA:", trade_pda.toString());

    let transaction = new Transaction();

    /// Accept Trade
    const accept_instr = AnagramInstructions.accept_trade(
      anagramProgramId,
      theirUser as PublicKey,
      yourUser as PublicKey,
      trade_pda,
      onChainTradeId,
      trade_pda_bump,
    );
    transaction.add(accept_instr);

    /// Approve Assets
    for (const trade of yourTrades) {
      let newApproveAmt = trade.actualAmt as number;
      let tokenAcct: Account = await getAccount(
        connection,
        new PublicKey(trade.tokenAcct)
      );
      if (tokenAcct.delegate?.toString() === delegateAcct.toString())
        newApproveAmt += Number(tokenAcct.delegatedAmount);
      const instr = Token.createApproveInstruction(
        TOKEN_PROGRAM_ID,
        new PublicKey(trade.tokenAcct),
        delegateAcct,
        yourUser as PublicKey,
        [],
        newApproveAmt
      );
      transaction.add(instr);
    }

    const fee_instr = SystemProgram.transfer({
      fromPubkey: yourUser as PublicKey,
      toPubkey: delegateAcct,
      lamports: 1000,
    });
    transaction.add(fee_instr);

    setModalTitle("waiting for token approval");
    setModalText("");
    setModalOpen(true);

    let signature, retries = 2;
    while (retries != 0) {
      try {
        signature = await sendTransaction(transaction, connection);
        retries = 0;
      } catch (e: any) {
        console.log((e as WalletError).error.response.status);
        if (retries == 0 || (e as WalletError).error.response.status != 503) {
          transactionFailedToSend(setModalTitle, setModalText, setModalOpen);
          return;
        } else {
          console.log("RPC 503 error, retrying");
          retries -= 1;
        }
      }
    }

    mixpanel.track("Approved a trade", {
      trade: yourTrades,
      trade_id: tradeIdInput,
      approval_signature: signature,
    });

    setModalTitle("waiting for transaction confirmation");
    setModalOpen(true);

    await connection.confirmTransaction(signature as string, "finalized");
    let execution_result = await executeTrade();
    setModalTitle("trade executed successfully");
    setModalText(
      "Deposit hash: " +
      execution_result.deposit_hash +
      "\n\nLock hash: " +
      execution_result.lock_hash +
      "\n\nWithdraw hash: " +
      execution_result.withdraw_hash
    );
    setModalOpen(true);

    mixpanel.track("Executed a trade", {
      trade_id: tradeIdInput,
    });

    props.passUpSidebarRefresh();
  };

  const cancelTrade = async () => {
    console.log("Cancel trade:", tradeIdInput);
    const res = await axios.get(
      serverUrl +
      "/cancel?id=" +
      tradeIdInput +
      "&user=" +
      publicKey?.toString()
    );
    console.log(res.data);

    mixpanel.track("Canceled a trade", {
      trade_id: tradeIdInput,
      res: res.data,
    });

    setModalTitle("trade canceled");
    setModalText("");
    setModalOpen(true);

    props.passUpSidebarRefresh();
  };

  const rejectTrade = async () => {
    console.log("Reject trade:", tradeIdInput);
    const res = await axios.get(
      serverUrl +
      "/reject?id=" +
      tradeIdInput +
      "&user=" +
      publicKey?.toString()
    );
    console.log(res.data);

    mixpanel.track('Rejected a trade', {
      'trade_id': tradeIdInput,
      'res': res.data
    });

    setModalTitle("trade rejected");
    setModalText("");
    setModalOpen(true);

    props.passUpSidebarRefresh();
  };

  const getTrade = async (e: any) => {
    let tradeToLoad = "";
    if (typeof e == "string") {
      tradeToLoad = e;
    } else {
      e.preventDefault();
      tradeToLoad = tradeIdInput;
    }
    if (tradeToLoad === "") return;
    console.log("Loading trade ID:", tradeToLoad);
    let res;
    try {
      res = await axios.get(
        serverUrl + "?id=" + tradeToLoad + "&user=" + publicKey?.toString()
      );
      setVis({
        propose: false,
        authorize: true,
        execute: true,
        portfolioView: false,
        tradeView: true,
        tradeViewEditLock: true,
      });
      setTradeIdInput(passedTradeId);
    } catch (e: any) {
      tradeNotFoundError(setModalOpen, setModalTitle, setModalText);
      return;
    }
    setYourTrades([]);
    setTheirTrades([]);
    let data = JSON.parse(res.data.trade);
    console.log(res.data);
    if (
      publicKey?.toString() !== data.initiator.pubkey &&
      publicKey?.toString() !== data.counterparty.pubkey
    ) {
      invalidParticipantError(setModalOpen, setModalTitle, setModalText);
      return;
    }
    let onchain_tradeid = new BN(data.onchain_tradeid);
    console.log("onchain_tradeid:", onchain_tradeid.toString());
    setOnChainTradeId(onchain_tradeid);

    let initiatorAssets: AssetData[] = [];
    let counterpartyAssets: AssetData[] = [];

    for (let asset of data.initiator.assets) {
      let adata = {
        name: asset.name,
        logoURI: asset.logoURI,
        tokenAcct: asset.tokenAcct,
        mint: asset.mint,
        isNFT: asset.isNFT,
        amount: asset.amount,
        decimal: asset.decimal,
        actualAmt: asset.actualAmt,
      };
      initiatorAssets.push(adata);
    }

    for (let asset of data.counterparty.assets) {
      let adata = {
        name: asset.name,
        logoURI: asset.logoURI,
        tokenAcct: asset.tokenAcct,
        mint: asset.mint,
        isNFT: asset.isNFT,
        amount: asset.amount,
        decimal: asset.decimal,
        actualAmt: asset.actualAmt,
      };
      counterpartyAssets.push(adata);
    }

    if (publicKey?.toString() === data.initiator.pubkey) {
      setTheirUser(new PublicKey(data.counterparty.pubkey));
      setYourTrades(initiatorAssets);
      setTheirTrades(counterpartyAssets);
    } else {
      setTheirUser(new PublicKey(data.initiator.pubkey));
      setYourTrades(counterpartyAssets);
      setTheirTrades(initiatorAssets);
    }
    setTradeStatus(res.data.status);
    setTradeRole(res.data.role);
    setTradeCounterparty(
      res.data.role == "init" ? data.counterparty.pubkey : data.initiator.pubkey
    );

    console.log("Trade loaded: " + tradeToLoad);
  };

  const executeTrade = async () => {
    console.log("Executing trade ID:", tradeIdInput, "with onchain ID:", onChainTradeId.toString());
    let rpc = connection.rpcEndpoint;
    let network: string = "";
    if (rpc == "https://api.mainnet-beta.solana.com/") {
      network = "mainnet";
    } else if (rpc == "https://api.devnet.solana.com") {
      network = "devnet";
    } else if (rpc == "https://api.testnet.solana.com") {
      network = "testnet";
    }
    setModalTitle("executing trade");
    setModalText("This could take a while. If it takes 30 seconds or more, please close this window and refresh to see the execution result.");
    setModalOpen(true);

    const res = await axios.get(
      serverUrl + "/execute?id=" + tradeIdInput + "&network=" + network
    );
    console.log(res.data);
    return res.data;
  };

  const shuffleTitle = () => {
    let array = ["a", "n", "a", "g", "r", "a", "m"];
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    let str = "";
    array.forEach((c) => {
      str = str + c;
    });
    setPageTitle(str);
  };

  return (
    <div
      style={{
        width: "80%",
        marginLeft: "20%",
      }}
    >
      <h1 className="anagram-title">{pageTitle}</h1>
      {vis.propose && (
        <h2 className="title" style={{ textAlign: "left" }}>
          what's your offer?
        </h2>
      )}
      {vis.authorize && (
        <div className="authorize-title-section">
          <small className="title" style={{ textAlign: "left" }}>
            {tradeIdInput}
          </small>
          <small className="subtitle">has been {tradeStatus}</small>
          <br />
          <small className="subtitle">
            {tradeRole == "init" ? "to" : "from"}
          </small>
          <small
            className="subtitle subtitle-bold"
            style={{ textAlign: "left" }}
          >
            {tradeCounterparty}
          </small>
        </div>
      )}
      <Grid container className="portfolio-views">
        <Grid item xs={6} className="your-portfolio-view">
          <SwapView
            isCurrUser={true}
            pubkey={publicKey ? publicKey.toString() : ""}
            passUpUser={setYourUser}
            assets={yourAssets}
            setAssets={setYourAssets}
            trades={yourTrades}
            setTrades={setYourTrades}
            vis={vis}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginRight: "-5px", background: "#ededed" }}
        />
        <Grid item xs={6} className="their-portfolio-view">
          <SwapView
            isCurrUser={false}
            pubkey={""}
            passUpUser={setTheirUser}
            assets={theirAssets}
            setAssets={setTheirAssets}
            trades={theirTrades}
            setTrades={setTheirTrades}
            vis={vis}
          />
        </Grid>
      </Grid>
      {vis.propose && (
        <button className="propose-button" onClick={postTrade}>
          [ propose ]
        </button>
      )}
      {vis.authorize && tradeRole === "cp" && tradeStatus === "proposed" && (
        <div className="load-trade-div">
          <button className="authorize-trade-button" onClick={approveForCp}>
            [ execute ]
          </button>
          <button
            className="authorize-trade-button"
            onClick={rejectTrade}
          >
            [ reject ]
          </button>
        </div>
      )}
      {vis.authorize && tradeRole === "init" && tradeStatus === "proposed" && (
        <div className="load-trade-div">
          <button className="authorize-trade-button" onClick={cancelTrade}>
            [ cancel ]
          </button>
        </div>
      )}
      <Modal
        open={modalOpen}
        text={modalText}
        title={modalTitle}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </div>
  );
};
