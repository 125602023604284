export function proposalFailed(
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("proposal failed");
  setModalText("Please check the proposed trade and try again");
}

export function authorizeFailed(
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("authorization failed");
  setModalText("Please check your wallet connection and try again");
}

export function approvalFailed(
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("trade not approved");
  setModalText("Please ensure both parties have approved the trade");
}

export function executeFailed(setModalTitle: Function, setModalText: Function) {
  setModalTitle("execution failed");
  setModalText("Verify your assets & try again");
}

export function assetQtyError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalOpen(true);
  setModalTitle("invalid quantity");
  setModalText("Please update the quantity and retry");
}

export function tradeQtyError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalOpen(true);
  setModalTitle("max # of assets");
  setModalText("Maximum of 16 assets per trade exceeded");
}

export function tradeEmptyError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("invalid proposal");
  setModalText("Assets need to be added for both sides of the trade");
  setModalOpen(true);
}

export function invalidPubkeyError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalOpen(true);
  setModalTitle("invalid pubkey");
  setModalText("Please check the entered value & try again");
}

export function invalidParticipantError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("load trade error");
  setModalText("Current user is not in the trade");
  setModalOpen(true);
}

export function tradeNotFoundError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("trade not found");
  setModalText("The trade entered does not exist");
  setModalOpen(true);
}

export function tradeIdEmptyError(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("trade id empty");
  setModalText("Please enter a valid trade ID");
  setModalOpen(true);
}

export function transactionFailedToSend(
  setModalOpen: Function,
  setModalTitle: Function,
  setModalText: Function
) {
  setModalTitle("Transaction Failed to send");
  setModalText("Check your wallet connection and retry");
  setModalOpen(true);
}
