import { FC } from "react";
import Dialog from '@mui/material/Dialog';

import "./Modal.css";

export interface ModalProps {
    open: boolean;
    title: string;
    text: string;
    onClose: (value: string) => void;
}

export const Modal: FC<ModalProps> = ({
    open,
    title,
    text,
    onClose,
}) => {

    const handleClose = () => {
        onClose(text);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div className="dialog-div">
                <h1 className="dialog-title">{title}</h1>
                <p className="dialog-text">{text}</p>
            </div>
        </Dialog>
    );
}

export default Modal;